*,
*::after,
*::after {
  box-sizing: border-box;
}

:root {
  --basic-font-size: 16px;
  font-size: var(--basic-font-size);
  font-family: sans-serif;
  line-height: 1.3;
}

body,
h1,
h2,
h3,
p,
ul,
ol,
li,
dl,
dt,
dd,
form,
fieldset,
legend,
input,
button,
textarea,
th,
td,
img {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;
  color: #333;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.Authentication-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.Authentication-form-container .Authentication-form {
  width: 620px;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: white;
  position: relative;
}
.Authentication-form-container .Authentication-form .Authentication-form-content {
  padding-left: 12%;
  padding-right: 12%;
}
.Authentication-form-container .Authentication-form .Authentication-form-content .Authentication-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: 800;
}
.Authentication-form-container .Authentication-form .Authentication-form-content .switchLabel {
  text-align: center;
  margin-bottom: 5%;
}

@media (max-width: 700px) {
  .Authentication-form {
    width: 80%;
  }
  .Authentication-form-content {
    padding-left: 10%;
    padding-right: 10%;
  }
}
.content {
  margin-left: 6.5em;
  margin-right: 3em;
  width: 100vh;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar {
  align-items: center;
  position: fixed;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
}
.sidebar i {
  text-decoration: none;
  color: white;
}

ul li:hover {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: pointer;
}

ul li:hover i {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

li {
  align-items: center;
  text-decoration: none;
}

.sidebar.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .burger-menu {
    display: block;
  }
  .sidebar {
    transform: translateX(-100%);
  }
  .sidebar.open {
    transform: translateX(0);
  }
  .content {
    margin-left: 1em;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.burger-menu {
  cursor: pointer;
  margin: 0.4em;
}

.burger-menu i.open {
  transform: rotate(-90deg);
}

.pageView {
  justify-content: center;
  align-items: center;
  height: auto;
}
.pageView .cardTable {
  table-layout: auto;
  margin-top: 1em;
}
.pageView .cardTable thead {
  border: 1px solid;
  overflow-y: auto;
}
.pageView .cardTable thead .card-answer-th {
  vertical-align: middle;
  border: 1px solid;
  min-width: 2em;
}
.pageView .cardTable thead .card-tag-th {
  vertical-align: middle;
  border: 1px solid;
  max-width: 9em;
}
.pageView .cardTable thead .card-question-th {
  vertical-align: middle;
  border: 1px solid;
  max-width: 25em;
}
.pageView .cardTable thead .card-delete-th {
  vertical-align: middle;
  border: 1px solid;
  width: 3em;
}
.pageView .cardTable thead .card-edit-th {
  vertical-align: middle;
  border: 1px solid;
  width: 3em;
}
.pageView .cardTable thead .card-export-th {
  vertical-align: middle;
  width: 3em;
}
.pageView .cardTable .card-td {
  vertical-align: middle;
}
.pageView .cardTable #card-delete-check {
  border-color: red;
}
.pageView .btn-card {
  margin: 1em;
  width: 10em;
  height: auto;
}

.deck-title {
  font-size: 2rem;
  text-decoration: none;
}

.deckbtn {
  width: 20em;
  height: 5em;
  margin: 1em;
}
.deckbtn .prop {
  color: white;
}

.deckmodal .btn {
  width: 5em;
}
.deckmodal .deckbtn {
  width: 100%;
  height: 10em;
  margin-left: 0%;
}
.deckmodal .deckbtn .prop {
  font-size: large;
  margin-bottom: 1em;
}

#editDeckBtn {
  transition: color 0.5s ease;
  position: relative;
}

#editDeckBtn:hover {
  background-color: #0275d8;
}
#editDeckBtn:hover .prop {
  font-size: large;
  color: #0275d8;
  margin-bottom: 1em;
}
#editDeckBtn:hover .progressbar {
  display: none;
}

#editDeckBtn:hover::before {
  content: "\f4ca";
  font-family: "bootstrap-icons";
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes wiggle {
  0%, 25% {
    transform: rotate(-2deg);
  }
  25%, 50% {
    transform: rotate(2deg);
  }
  50%, 75% {
    transform: rotate(2deg);
  }
  75%, 100% {
    transform: rotate(-2deg);
  }
}
.wiggle {
  animation: wiggle 0.2s ease-in-out infinite;
}

.quizgroup {
  width: 15em;
}

.CreateCard-form-container {
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}
.CreateCard-form-container .CreateCard-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 10px;
  border-radius: 8px;
  background-color: white;
}
.CreateCard-form-container .CreateCard-form .CreateCard-form-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 2em;
}
.CreateCard-form-container .CreateCard-form .CreateCard-form-content .full-width {
  width: 100%;
}
.CreateCard-form-container .CreateCard-form .CreateCard-form-content .CreateCard-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: 800;
}
.CreateCard-form-container .CreateCard-form .CreateCard-form-content .textfield {
  min-height: 10em;
}
.CreateCard-form-container .card-editBtn {
  width: 10em;
  height: auto;
}
.CreateCard-form-container .card-plus-minus {
  width: 2.5em;
  height: auto;
}

.card-createBtn {
  width: 10em;
  height: auto;
}

.chart-size {
  width: 100%;
  height: 400px;
}

.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.wf-layout-layout {
  display: grid;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.statcell {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 18px;
}

.profilestat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcb-ce66f49c {
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcc-ce66f49c, #w-node-_529fa684-4954-f5f7-dac7-d1100173d7c5-ce66f49c, #w-node-bc6fba58-27f4-331b-04cb-0cc01c5317bc-ce66f49c {
  grid-column: span 2/span 2;
}

.deleteModal {
  background-color: gray;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: #198754;
  margin-left: 60px;
  color: #fff;
}

@media (max-width: 768px) {
  .custom-file-upload {
    margin-left: 20px;
  }
}
@media (max-width: 385px) {
  .custom-file-upload {
    margin-left: 0px;
  }
}
.custom-file-upload::before {
  content: "\f4da";
  font-family: "bootstrap-icons";
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.custom-file-upload input[type=file] {
  display: none;
}

.custom-file-upload:hover::before {
  opacity: 0;
}

.img-wrap {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f357";
  font-family: "bootstrap-icons";
  font-size: 60px;
  position: absolute;
  padding-top: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background: #198754;
  color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

img {
  max-width: 100%;
}

.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #198754;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preloader-animation {
  position: relative;
  border: 6px solid #198754;
  border-top: 6px solid #f3f3f3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
}

.preloader-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader-logo {
  padding-left: 0.5rem;
  width: 120px;
  height: 120px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-badge {
  transition: box-shadow 0.3s ease;
}

.custom-badge:hover {
  box-shadow: 0 0 10px rgb(0, 0, 0);
}


