*,
*::after,
*::after {
    box-sizing: border-box;
}

:root {
    --basic-font-size: 16px;
    font-size: var(--basic-font-size);
    font-family: sans-serif;
    line-height: 1.3;
}

body,
h1,
h2,
h3,
p,
ul,
ol,
li,
dl,
dt,
dd,
form,
fieldset,
legend,
input,
button,
textarea,
th,
td,
img {
    margin: 0;
    padding: 0;
    border: 0;
}

a {
    text-decoration: none;
    color: #333;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
  