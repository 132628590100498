.pageView {
    justify-content: center;
    align-items: center;
    height: auto;

    .cardTable {
        table-layout: auto;
        margin-top: 1em;


        thead {
            border: 1px solid;
            overflow-y: auto;

            .card-answer-th {
                vertical-align: middle;
                border: 1px solid;
                min-width: 2em;
            }

            .card-tag-th {
                vertical-align: middle;
                border: 1px solid;
                max-width: 9em;
            }

            .card-question-th {
                vertical-align: middle;
                border: 1px solid;
                max-width: 25em;
            }

            .card-delete-th {
                vertical-align: middle;
                border: 1px solid;
                width: 3em;
            }

            .card-edit-th {
                vertical-align: middle;
                border: 1px solid;
                width: 3em;
            }

            .card-export-th {
                vertical-align: middle;
                width: 3em;
            }
        }

        .card-td {
            vertical-align: middle;
        }

        #card-delete-check {
            border-color: red;
        }

    }

    .btn-card {
        margin: 1em;
        width: 10em;
        height: auto;
    }
}

.deck-title {
    font-size: 2rem;
    text-decoration: none;
}