.Authentication-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .Authentication-form {
    width: 620px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 8px;
    background-color: white;
    position: relative;

    .Authentication-form-content {
      padding-left: 12%;
      padding-right: 12%;

      .Authentication-form-title {
        text-align: center;
        margin-bottom: 1em;
        font-size: 24px;
        color: rgb(0, 0, 0);
        font-weight: 800;
      }

      .switchLabel {
        text-align: center;
        margin-bottom: 5%;
      }
    }
  }
}

@media (max-width: 700px) {
  .Authentication-form {
    width: 80%;
  }

  .Authentication-form-content {
    padding-left: 10%;
    padding-right: 10%;
  }
}

