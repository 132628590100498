// DELETE_MODAL
.deleteModal {
  background-color: gray;
}


// IMAGE_UPLOAD
.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: #198754;
  margin-left: 60px;
  color: #fff;
}

@media (max-width: 768px) {
  .custom-file-upload {
    margin-left: 20px;
  }
}

@media (max-width: 385px) {
  .custom-file-upload {
    margin-left: 0px;
  }
}

.custom-file-upload::before {
  content: "\F4DA";
  font-family: 'bootstrap-icons';
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload:hover::before {
  opacity: 0;
}

.img-wrap {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\F357";
  font-family: 'bootstrap-icons';
  font-size: 60px;
  position: absolute;
  padding-top: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background: #198754;
  color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

img {
  max-width: 100%;
}


// PRELOADER
.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #198754;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preloader-animation {
  position: relative;
  border: 6px solid #198754;
  border-top: 6px solid #f3f3f3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
}

.preloader-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader-logo {
  padding-left: 0.5rem;
  width: 120px;
  height: 120px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// BREADCRUMB
.custom-badge {
  transition: box-shadow 0.3s ease;
}

.custom-badge:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
