.content {
    margin-left: 6.5em;
    margin-right: 3em;
    width: 100vh;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar {
    align-items: center;
    position: fixed;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;

    i {
        text-decoration: none;
        color: white;
    }
}

ul li:hover {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
}

ul li:hover i {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

li {
    align-items: center;
    text-decoration: none;
}

.sidebar.open {
    transform: translateX(0);
}

@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }

    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .content {
        margin-left: 1em;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.burger-menu {
    cursor: pointer;
    margin: 0.4em;
}

.burger-menu i.open {
    transform: rotate(-90deg);
}