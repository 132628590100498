.CreateCard-form-container {
    justify-content: center;
    align-items: center;
    margin-top: 5em;

    .CreateCard-form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 30%) 1px 1px 10px;
        border-radius: 8px;
        background-color: white;

        .CreateCard-form-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            margin: 2em;

            .full-width {
                width: 100%;
            }

            .CreateCard-form-title {
                text-align: center;
                margin-bottom: 1em;
                font-size: 24px;
                color: rgb(0, 0, 0);
                font-weight: 800;
            }

            .textfield {
                min-height: 10em;
            }
        }
    }

    .card-editBtn {
        width: 10em;
        height: auto;
    }

    .card-plus-minus {
        width: 2.5em;
        height: auto;
    }
}

.card-createBtn {
    width: 10em;
    height: auto;
}
