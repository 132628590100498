.deckbtn {
    width: 20em;
    height: 5em;
    margin: 1em;

    .prop {
        color: white;
    }
}

.deckmodal {
    .btn {
        width: 5em;
    }

    .deckbtn {
        width: 100%;
        height: 10em;
        margin-left: 0%;

        .prop {
            font-size: large;
            margin-bottom: 1em;
        }
    }
}

#editDeckBtn {
    transition: color 0.5s ease;
    position: relative;
}

#editDeckBtn:hover {
    background-color: #0275d8;

    .prop {
        font-size: large;
        color: #0275d8;
        margin-bottom: 1em;
    }

    .progressbar {
        display: none;
    }
}

#editDeckBtn:hover::before {
    content: '\F4CA';
    font-family: 'bootstrap-icons';
    font-size: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes wiggle {

    0%,
    25% {
        transform: rotate(-2deg);
    }

    25%,
    50% {
        transform: rotate(2deg);
    }

    50%,
    75% {
        transform: rotate(2deg);
    }

    75%,
    100% {
        transform: rotate(-2deg);
    }
}

.wiggle {
    animation: wiggle 0.2s ease-in-out infinite;
}