// .div-block {
//   max-width: 1000px;
//   grid-column-gap: 20px;
//   grid-row-gap: 20px;
//   flex-flow: wrap;
//   justify-content: center;
//   align-items: stretch;
//   margin-top: 20px;
//   display: flex;
//   position: static;
// }


// .statblock {
//   border: 1px solid #000;
//   border-radius: 16px;
//   flex: 0 auto;
//   margin: 16px;
//   padding: 16px;
// }

// .statp {
//   font-size: 18px;
//   font-weight: 700;
// }

// .profileblock {
//   display: flex;
// }

// .profileblock.statblock {
//   width: auto;
//   min-height: 0;
//   grid-column-gap: 48px;
//   grid-row-gap: 48px;
//   object-fit: fill;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   padding: 30px;
// }

// .profileblock.statblock.extrawidth {
//   min-height: 50vh;
//   min-width: 900px;
// }


// .div-block-2 {
//   max-width: none;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
//   display: flex;
// }



.chart-size{
  width: 100%;
  height: 400px;
}


.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.wf-layout-layout {
  display: grid;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.statcell {
  // border: 1px solid #c1c1c1;
  // border-radius: 15px;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 18px;
}

.profilestat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcb-ce66f49c {
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_78aa92e7-e352-78dd-7aca-bd1e135dfbcc-ce66f49c, #w-node-_529fa684-4954-f5f7-dac7-d1100173d7c5-ce66f49c, #w-node-bc6fba58-27f4-331b-04cb-0cc01c5317bc-ce66f49c {
  grid-column: span 2 / span 2;
}